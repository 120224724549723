<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="article, actions"
    ></v-skeleton-loader>
    <SettingsBusinessInfoCardVue
      v-else
      :company-infos="settings"
      @companyUpdate="companyUpdate"
    ></SettingsBusinessInfoCardVue>
    <v-skeleton-loader
      v-if="loading"
      type="article, actions"
    ></v-skeleton-loader>
    <settings-business-bank-infos-card
      v-else
      :company-infos="settings"
      @companyUpdate="companyUpdate"
    ></settings-business-bank-infos-card>
    <v-skeleton-loader
      v-if="loading"
      type="article, actions"
    ></v-skeleton-loader>
    <div v-else>
      <SettingsBusinessVisualInfosCardVue
        :company="settings"
        @companyUpdate="companyUpdate"
      ></SettingsBusinessVisualInfosCardVue>
      <SettingsBusinessEmailSignatureCardVue
        :company-email-signature="settings.emailSignature"
        :website="settings.website"
        @companyUpdate="companyUpdate"
      ></SettingsBusinessEmailSignatureCardVue>
    </div>
  </div>
</template>

<script>
import SettingsBusinessInfoCardVue from './SettingsBusinessInfoCard.vue'
import SettingsBusinessVisualInfosCardVue from './SettingsBusinessVisualInfosCard.vue'
import SettingsBusinessEmailSignatureCardVue from './SettingsBusinessEmailSignatureCard.vue'
import SettingsBusinessBankInfosCard from './SettingsBusinessBankInfosCard.vue'

export default {
  metaInfo: {
    title: 'Paramètres de l\'entreprise',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    SettingsBusinessInfoCardVue,
    SettingsBusinessBankInfosCard,
    SettingsBusinessVisualInfosCardVue,
    SettingsBusinessEmailSignatureCardVue,
  },
  data() {
    return {
      loading: false,
      settings: this.$store.state.company,
    }
  },
  mounted() {
  },
  methods: {
    companyUpdate(payload) {
      this.settings = payload
    },
  },
}
</script>

<style>

</style>
