<template>
  <v-card class="overflow-hidden my-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon

        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Informations bancaires
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiBank }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div
        v-show="show"
      >
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="bankName">Nom de la banque</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="bankName"
                  v-model="rib.bank"
                  outlined
                  dense
                  placeholder="Nom de la banque"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <label for="address1">BIC</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="bic"
                  v-model="rib.bic"
                  outlined
                  dense
                  placeholder="BIC"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="iban">IBAN</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="iban"
                  v-model="rib.iban"
                  outlined
                  dense
                  placeholder="IBAN"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <label for="rib">RIB</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="rib"
                  v-model="rib.rib"
                  md="9"
                  outlined
                  dense
                  placeholder="RIB"
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn
                class="mx-2"
                outlined
                @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                :disabled="!hasChanged"
                color="primary"
                @click="updateCompany"
              >
                Mettre à jour
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiBank } from '@mdi/js'

export default {
  props: {
    companyInfos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      rib: {
        bank: '',
        iban: '',
        bic: '',
        rib: '',
      },
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiBank,
      },
    }
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.rib) !== JSON.stringify(this.companyInfos.rib)
    },
  },
  mounted() {
    this.rib = { ...this.companyInfos.rib }
  },
  methods: {
    reset() {
      this.rib = { ...this.companyInfos.rib }
    },
    updateCompany() {
      const company = { rib: this.rib }
      this.$http.patch(`${process.env.VUE_APP_API_URL}/company`, company)
        .then(res => {
          this.$emit('companyUpdate', res.data.company)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Entreprise mise à jour',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur',
            value: true,
          })
        })
        .finally(

        )
    },
  },
}
</script>

<style>
</style>
