<template>
  <v-card class="overflow-hidden my-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Apparence de l'entreprise
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiBrush }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="name">Nom d'affichage</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="displayName"
                  v-model="companyVis.displayName"
                  outlined
                  dense
                  placeholder="Nom d'affichage de l'entreprise"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="colorScheme">Couleurs d'affichage</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-item-group
                  v-model="matchingScheme"
                  mandatory
                  @change="updateMatchingScheme"
                >
                  <v-row>
                    <v-col
                      v-for="(schema, index) in colorSchemes"
                      :key="index"
                      cols="12"
                      md="4"
                    >
                      <v-item v-slot="{ active, toggle }">
                        <v-card
                          :color="schema.color"
                          class="d-flex align-center"
                          dark
                          height="200"
                          @click="toggle"
                        >
                          <v-scroll-y-transition>
                            <div
                              v-if="active"
                              class="text-h2 flex-grow-1 text-center"
                            >
                              {{ schema.name }} <v-icon x-large>
                                {{ icons.mdiCheckCircle }}
                              </v-icon>
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn
                class="mx-2"
                outlined
                @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                :disabled="!hasChanged"
                color="primary"
                @click="updateCompany"
              >
                Mettre à jour
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiChevronUp, mdiChevronDown, mdiBrush, mdiCheckCircle,
} from '@mdi/js'

export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      matchingScheme: 1,
      companyVis: {
        displayName: '',
        colorScheme: 'violet',
        logo: null,
      },
      colorSchemes: [
        {
          name: 'violet',
          color: 'primary',
        },
        {
          name: 'orange',
          color: '#FF9800',
        },
        {
          name: 'dark',
          color: '#424242',
        },
      ],
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiBrush,
        mdiCheckCircle,
      },
    }
  },
  computed: {
    hasChanged() {
      const originalData = {
        displayName: this.company.displayName,
        colorScheme: this.company.colorScheme,
        logo: this.company.logo,
      }

      return JSON.stringify(this.companyVis) !== JSON.stringify(originalData)
    },
  },
  watch: {

  },
  mounted() {
    this.companyVis = {
      displayName: this.company.displayName,
      colorScheme: this.company.colorScheme,
      logo: this.company.logo,
    }
  },
  methods: {
    updateMatchingScheme(e) {
      this.companyVis.colorScheme = this.colorSchemes[e].name
    },
    reset() {
      this.companyVis = {
        displayName: this.company.displayName,
        colorScheme: this.company.colorScheme,
        logo: this.company.logo,
      }
    },
    updateCompany() {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/company`, this.companyVis)
        .then(res => {
          this.$emit('companyUpdate', res.data.company)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Entreprise mise à jour',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur',
            value: true,
          })
        })
        .finally(

        )
    },
  },
}
</script>

<style>
</style>
