<template>
  <v-card class="overflow-hidden my-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Signature e-mail
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiMail }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="color">Choisir la couleur</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-row
                  class="justify-left align-left"
                >
                  <v-col
                    class="shrink"
                    style="min-width: 220px;"
                  >
                    <v-text-field
                      v-model="emailSignature.color"
                      dense
                      outlined
                      hide-details
                      class="ma-0 pa-0"
                      solo
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="menu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              :style="swatchStyle"
                              v-on="on"
                            >
                            </div>
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                v-model="emailSignature.color"
                                flat
                                show-swatches
                                swatches-max-height="200"
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="logo">Choisir le logo</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <div v-if="emailSignature.logo === null && preview === null">
                  <v-file-input
                    v-model="upload"
                    label="Choisir le logo"
                    chips
                    dense
                    outlined
                    filled
                    :prepend-icon="icons.mdiImage"
                    @change="previewUpload"
                  ></v-file-input>
                </div>
                <div v-else>
                  <v-img
                    width="300"
                    :src="getPath()"
                  >
                    <v-btn
                      icon
                      class="deleteIcon align-right"
                      @click="removeLogo"
                    >
                      <v-icon color="error">
                        {{ icons.mdiCloseCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </v-img>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="template">Modèle de signature</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-btn-toggle
                  v-model="emailSignature.template"
                  tile
                  color="primary"
                  group
                >
                  <v-btn :value="1">
                    Modèle 1
                  </v-btn>

                  <v-btn
                    :value="2"
                    disabled
                  >
                    Modèle 2
                  </v-btn>

                  <v-btn
                    :value="3"
                    disabled
                  >
                    Modèle 3
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="example">Pré-visualisation</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <div v-html="renderedSignature">
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn
                class="mx-2"
                outlined
                @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                :disabled="!hasChanged"
                color="primary"
                @click="updateCompany"
              >
                Mettre à jour
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiChevronUp, mdiChevronDown, mdiMail, mdiImage, mdiCloseCircleOutline,
} from '@mdi/js'

export default {
  props: {
    companyEmailSignature: {
      type: Object,
      default: () => {},
    },
    website: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: true,
      menu: false,
      upload: null,
      emailSignature: {
        color: '#7B1FA2',
        template: 1,
        logo: null,
      },
      preview: null,
      templates: [
        {
          name: 'Modèle 1',
          id: 1,
          content: `<div>
                        <table>
                            <tr>
                                <td style="padding: 7px">
                                    <img src="src_to_replace"
                                        alt="" width="200" height="80"
                                        style="display:block; border-radius: 50%; margin-right: 7px; margin-top:20px; float: left">
                                    <div style="width: 5px; height: 120px; background:color_to_replace; float: right">
                                </td>
                                <td style="vertical-align:top; padding:25px 14px 7px 3px">
                                    <strong
                                        style="margin: 0; font-size:18px; color: #000000; line-height: 24px; height: 24px; display:block">display_name_to_replace</strong>
                                    <p style='font-size:16px; margin: 0px 0 6px;'>
                                        <span style="margin: 0; color: #000">job_title_to_replace</span>
                                    </p>
                                    <p style='font-size:14px; margin: 0px 0 6px;'>
                                        <a style="text-decoration: none;" href="tel:phone_to_replace"><span style="margin: 0; color: #000">Tel: phone_to_replace</span></a>
                                    </p>
                                    <p style='font-size:14px; margin: 0px 0 6px;'>
                                        <a style="text-decoration: none;" href="website_to_replace"><span style="margin: 0; color: #000">website_to_replace</span></a>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>`,
        },
        {
          name: 'Modèle 1',
          id: 1,
          content: '',
        }],
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiMail,
        mdiImage,
        mdiCloseCircleOutline,
      },
    }
  },
  computed: {
    renderedSignature() {
      if (this.templates) {
        const template = this.templates.find(x => x.id === this.emailSignature.template).content
        const mapObj = {
          src_to_replace: this.getPath(),
          color_to_replace: this.emailSignature.color,
          display_name_to_replace: 'Nom d\'affichage',
          job_title_to_replace: 'Poste occupé',
          phone_to_replace: '06 12 34 56 78',
          website_to_replace: this.website,
        }
        const reg = new RegExp(Object.keys(mapObj).join('|'), 'gi')
        const signature = template.replace(reg, matched => mapObj[matched])

        return signature
      }

      return ''
    },
    hasChanged() {
      return (JSON.stringify(this.emailSignature) !== JSON.stringify(this.companyEmailSignature) || this.upload !== null)
    },
    swatchStyle() {
      const { color } = this.emailSignature
      const { menu } = this

      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
  mounted() {
    this.emailSignature = { ...this.companyEmailSignature }
  },
  methods: {
    getPath() {
      if (this.emailSignature.logo === null) {
        return this.preview
      }

      return `${process.env.VUE_APP_API_URL}/${this.emailSignature.logo.path}`
    },
    removeLogo() {
      this.emailSignature.logo = null
      this.preview = null
      this.upload = null
    },
    previewUpload() {
      this.preview = window.URL.createObjectURL(this.upload)
    },
    reset() {
      this.emailSignature = { ...this.companyEmailSignature }
    },
    updateCompany() {
      if (this.preview !== null) {
        const formData = new FormData()

        formData.append('file', this.upload, this.upload.name)
        this.$http.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
          .then(res => {
            this.emailSignature.logo = res.data
            this.updateCompanyPut()
          })
          .catch(err => console.log(err))
      } else {
        this.updateCompanyPut()
      }
    },
    updateCompanyPut() {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/company`, {
        emailSignature: this.emailSignature,
      })
        .then(res => {
          this.$emit('companyUpdate', res.data.company)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Entreprise mise à jour',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur',
            value: true,
          })
        })
        .finally(
        )
    },
    updatedSignature(e) {
      console.log(e)
    },
  },
}
</script>

<style>
.deleteIcon{
    z-index: 2;
    right: 0;
    position: absolute;
}
</style>
