<template>
  <v-card class="overflow-hidden my-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon

        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Informations de l'entreprise
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiInformation }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div
        v-show="show"
      >
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <label for="name">Nom de l'entreprise</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="name"
                  v-model="company.name"
                  outlined
                  dense
                  placeholder="Nom de l'entreprise"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <label for="address1">Adresse ligne 1</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="address1"
                  v-model="company.addressLine1"
                  outlined
                  dense
                  placeholder="Adresse ligne 1"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="address2">Adresse ligne 2</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="address2"
                  v-model="company.addressLine2"
                  outlined
                  dense
                  placeholder="Adresse ligne 2"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <label for="city">Ville</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="city"
                  v-model="company.city"
                  md="9"
                  outlined
                  dense
                  placeholder="Ville"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <label for="postcode">Code Postal</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="postcode"
                  v-model="company.postcode"
                  outlined
                  dense
                  placeholder="Code Postal"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="siren">Numéro SIREN</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="siren"
                  v-model="company.siren"
                  outlined
                  dense
                  placeholder="Numéro SIREN"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="siret">Numéro SIRET</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="siret"
                  v-model="company.siret"
                  outlined
                  dense
                  placeholder="Numéro SIRET"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="declarationActivite">Numéro de déclaration d'activité</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="declarationActivite"
                  v-model="company.nda"
                  outlined
                  dense
                  placeholder="Numéro de déclaration d'activité"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="email">E-mail</label>
              </v-col>

              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="email"
                  v-model="company.email"
                  outlined
                  dense
                  placeholder="Adresse e-mail"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="phone">Numéro de téléphone </label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="phone"
                  v-model="company.phone"
                  outlined
                  dense
                  placeholder="Téléphone"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <label for="website">Site Internet</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  id="website"
                  v-model="company.website"
                  outlined
                  dense
                  placeholder="Site Internet"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end mt-5">
              <v-btn
                class="mx-2"
                outlined
                @click="reset"
              >
                Annuler
              </v-btn>
              <v-btn
                :disabled="!hasChanged"
                color="primary"
                @click="updateCompany"
              >
                Mettre à jour
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mdiChevronUp, mdiChevronDown, mdiInformation } from '@mdi/js'

export default {
  props: {
    companyInfos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      company: {
        name: '',
        address: {
          line1: '',
          line2: '',
          postcode: '',
          city: '',
        },
        phone: '',
        siret: '',
        siren: '',
        email: '',
        website: '',
        nda: '',
      },
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiInformation,
      },
    }
  },
  computed: {
    hasChanged() {
      return JSON.stringify(this.company) !== JSON.stringify(this.companyInfos)
    },
  },
  mounted() {
    this.company = { ...this.companyInfos }
  },
  methods: {
    reset() {
      this.company = { ...this.companyInfos }
    },
    updateCompany() {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/company`, this.company)
        .then(res => {
          this.$emit('companyUpdate', res.data.company)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Entreprise mise à jour',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur',
            value: true,
          })
        })
        .finally(

        )
    },
  },
}
</script>

<style>
</style>
